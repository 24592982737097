import { ErrorMessageResponse, ErrorTypesEnum } from '@/types';
import { useGeneral } from '@/store';
import * as Sentry from '@sentry/browser';

export async function apiHandler<T>(
  api: () => Promise<T | ErrorMessageResponse>,
): Promise<T | null> {
  const {
    setLoading,
    handleExpectedError,
    handleUnexpectedError,
  } = useGeneral();

  setLoading(true);
  try {
    const response = await api();

    if (!response || typeof response !== 'object') {
      handleUnexpectedError({
        error: 'ERROR__SERVER_ERROR',
        code: ErrorTypesEnum.ERROR__SERVER_ERROR,
      });
      return null;
    }

    // response has error, from API
    if ('error' in response) {
      Sentry.withScope((scope) => {
        scope.setTag('type', 'client');
        Sentry.captureMessage(`[Monitor] ${JSON.stringify(response)}`);
      });
      handleExpectedError(response);
      return null;
    }

    return response;
  } catch (err) {
    Sentry.withScope((scope) => {
      scope.setTag('type', 'client');
      Sentry.captureException(err);
    });
    const error = err as any;

    if (error?.response?.status === 408 || error?.code === 'ECONNABORTED') {
      // handle timeout
      handleExpectedError({
        error: 'ERROR__FRONT_END_ERROR',
        code: ErrorTypesEnum.ERROR__FRONT_END_ERROR,
      });
    } else
      handleUnexpectedError({
        error: 'ERROR__SERVER_ERROR',
        code: ErrorTypesEnum.ERROR__SERVER_ERROR,
      });
    throw err;
  } finally {
    setLoading(false);
  }
}
