import { createElementVNode as _createElementVNode, renderSlot as _renderSlot, createCommentVNode as _createCommentVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, normalizeClass as _normalizeClass, mergeProps as _mergeProps } from "vue"

const _hoisted_1 = {
  key: 0,
  class: "modal-content-header"
}
const _hoisted_2 = ["innerHTML"]
const _hoisted_3 = { class: "modal-content-body" }
const _hoisted_4 = {
  key: 0,
  class: "modal-content-footer"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _mergeProps({ class: "modal" }, _ctx.$attrs), [
    _createElementVNode("div", {
      class: "modal-mask",
      onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.$emit('on-click-mask')))
    }),
    _createElementVNode("div", {
      class: _normalizeClass(["modal-content", {
        'modal-content--normal': $props.type === $setup.ModalTypeEnum.NORMAL,
        'modal-content--large': $props.type === $setup.ModalTypeEnum.LARGE,
        'modal-content--custom': $props.type === $setup.ModalTypeEnum.CUSTOM,
      }])
    }, [
      ($props.isCloseButtonVisible)
        ? _renderSlot(_ctx.$slots, "close-btn", { key: 0 }, () => [
            _createElementVNode("div", {
              class: "modal-close-btn",
              onClick: _cache[1] || (_cache[1] = ($event: any) => (_ctx.$emit('on-click-cancel')))
            })
          ])
        : _createCommentVNode("", true),
      _renderSlot(_ctx.$slots, "header", {}, () => [
        ($props.title)
          ? (_openBlock(), _createElementBlock("header", _hoisted_1, [
              _createElementVNode("h1", { innerHTML: $props.title }, null, 8, _hoisted_2)
            ]))
          : _createCommentVNode("", true)
      ]),
      _createElementVNode("section", _hoisted_3, [
        _renderSlot(_ctx.$slots, "body")
      ]),
      _renderSlot(_ctx.$slots, "footer", {}, () => [
        ($props.isCancelButtonVisible || $props.isConfirmButtonVisible)
          ? (_openBlock(), _createElementBlock("footer", _hoisted_4, [
              ($props.isCancelButtonVisible)
                ? (_openBlock(), _createElementBlock("button", {
                    key: 0,
                    onClick: _cache[2] || (_cache[2] = ($event: any) => (_ctx.$emit('on-click-cancel'))),
                    class: "modal-cancel-button"
                  }, _toDisplayString($props.cancelText), 1))
                : _createCommentVNode("", true),
              ($props.isConfirmButtonVisible)
                ? (_openBlock(), _createElementBlock("button", {
                    key: 1,
                    onClick: _cache[3] || (_cache[3] = ($event: any) => (_ctx.$emit('on-click-confirm'))),
                    class: "modal-confirm-button"
                  }, _toDisplayString($props.confirmText), 1))
                : _createCommentVNode("", true)
            ]))
          : _createCommentVNode("", true)
      ])
    ], 2)
  ], 16))
}