
import { useModal } from '@/store';
import Modal from '../Base/Modal.vue';

export default {
  components: {
    Modal,
  },
  setup() {
    const { setModal } = useModal();

    return {
      setModal,
    };
  },
};
