
import { ref, defineComponent, toRef } from 'vue';
import { i18n } from '@/i18n';
import { useGeneral, useProfile } from '@/store';
import { ErrorTypesEnum } from '@/types';
import ThaiAgreement from './views/ThaiAgreement.vue';

// components
import Home from './views/Home.vue';
import Modals from './components/Modals/index.vue';
import FullScreenAnimation from './components/FullScreenAnimation/index.vue';
import Loading from './components/Base/Loading.vue';
import ClickEffect from './components/ClickEffect.vue';

export default defineComponent({
  name: 'App',
  components: {
    Home,
    ThaiAgreement,
    Modals,
    FullScreenAnimation,
    Loading,
    ClickEffect,
  },
  setup() {
    const { loadLocale } = i18n();
    const isInitialized = ref(false);
    const { profileState, getProfile } = useProfile();
    const { generalState, handleExpectedError } = useGeneral();
    const loadedImgPercentage = toRef(generalState, 'loadedImgPercentage');
    const isLoading = toRef(generalState, 'isLoading');
    const isShowThaiAgreement = toRef(profileState, 'isShowThaiAgreement');
    const isTHRegion = process.env.LOCALE === 'th';
    const videoPath = `${process.env.CDN}/mainpage/mainpage-background.mp4`;

    const isIngame =
      localStorage.getItem('TOKEN') && localStorage.getItem('PARTITION');
    if (!isIngame) {
      handleExpectedError({
        error: 'ERROR__NOT_INGAME',
        code: ErrorTypesEnum.ERROR__OUT_GAME,
      });
    }

    (async () => {
      await loadLocale(process.env.LOCALE);

      if (isIngame) {
        isInitialized.value = true;
        await getProfile();
      }
    })();

    return {
      videoPath,
      isInitialized,
      isLoading,
      loadedImgPercentage,
      isShowThaiAgreement,
      isTHRegion,
    };
  },
});
