import service from '@/services';
import { reactive, readonly } from 'vue';
import { apiHandler } from '@/store/utils';
import { useModal } from '@/store';
import { ModalEnum, HistoryItem } from '@/types';

interface HistoryState {
  history: HistoryItem[];
  currentClaim: HistoryItem[];
  maxSelectedItemCount: number;
  showHint: boolean;
}

const historyState = reactive<HistoryState>({
  history: [],
  currentClaim: [],
  maxSelectedItemCount: 0,
  showHint: false,
});

function setHistory({
  history,
  maxSelectedItemCount,
}: {
  history: HistoryItem[];
  maxSelectedItemCount?: number;
}) {
  historyState.history = history;
  if (maxSelectedItemCount) {
    historyState.maxSelectedItemCount = maxSelectedItemCount;
  }
}

function setCurrentClaim(currentClaim: HistoryItem[]) {
  historyState.currentClaim = currentClaim;
}
async function postHistoryClaim() {
  const response = await apiHandler(() =>
    service.postHistoryClaim({
      rewardIds: historyState.currentClaim.map((item) => item.id),
    }),
  );

  if (response) {
    const { history, showHint } = response;
    const { setModal } = useModal();

    historyState.showHint = showHint;
    setHistory({ history });
    setModal(ModalEnum.NOTIFY_CLAIM);
  }
}

export default () => {
  return {
    historyState: readonly(historyState),
    setHistory,
    postHistoryClaim,
    setCurrentClaim,
  };
};
