
import { computed } from 'vue';
import { useMilestone, useModal, useProfile, useDraw } from '@/store';
import { ModalTypeEnum, ModalEnum } from '@/types';
import Modal from '../Base/Modal.vue';

export default {
  components: {
    Modal,
  },
  setup() {
    const { profileState } = useProfile();
    const { setModal } = useModal();
    const { drawState } = useDraw();
    const { milestoneState } = useMilestone();
    const jackpot = computed(() => {
      const [jackpot] = milestoneState.milestones.slice(0);
      return jackpot;
    });
    const nickname = computed(() => profileState.nickname);

    function handleCloseModal() {
      setModal(null);
      if (drawState.isFirstDraw) {
        setModal(ModalEnum.REMIND_HISTORY);
      }
    }

    return {
      jackpot,
      nickname,
      handleCloseModal,
      ModalTypeEnum,
    };
  },
};
