
import { computed, defineComponent } from 'vue';
import { MilestoneTypeEnum } from '@/types';

export default defineComponent({
  props: {
    image: {
      type: String,
      required: true,
    },
    target: {
      type: Number,
      required: true,
    },
    status: {
      type: Number, // 0: not reach target, 1: reach target, 2: redeem
      default: 0,
    },
    name: {
      type: String,
      default: 'test',
    },
    isJackpot: {
      type: Boolean,
      default: false,
    },
  },
  setup(props: { status: MilestoneTypeEnum }) {
    const isRedeemed = computed(
      () => props.status === MilestoneTypeEnum.REDEEM,
    );
    const isRedeemable = computed(
      () => props.status === MilestoneTypeEnum.REACH_TARGET,
    );
    return { isRedeemed, isRedeemable };
  },
});
