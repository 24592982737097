
import { useModal } from '@/store';
import { ModalEnum } from '@/types';

export default {
  setup() {
    const { setModal } = useModal();

    function handleClickRule() {
      setModal(ModalEnum.RULES_AND_TUTORIALS);
    }

    return {
      handleClickRule,
    };
  },
};
