
import { computed, defineComponent } from 'vue';
import { useModal } from '@/store';
import ErrorMessage from './ErrorMessage.vue';
import ConfirmDraw from './ConfirmDraw.vue';
import ConfirmDiscountDraw from './ConfirmDiscountDraw.vue';
import ConfirmClaim from './ConfirmClaim.vue';
import YouTubeVideo from './YouTubeVideo.vue';
import RulesAndTutorial from './RulesAndTutorial.vue';
import NotifyReward from './NotifyReward.vue';
import HistoryRecord from './HistoryRecord.vue';
import NotifyClaim from './NotifyClaim.vue';
import NotifyRedeem from './NotifyRedeem.vue';
import EnemyInfo from './EnemyInfo.vue';
import RemindHistory from './RemindHistory.vue';
import NotifyJackpot from './NotifyJackpot.vue';
import NotifyClaimHint from './NotifyClaimHint.vue';

export default defineComponent({
  components: {
    ErrorMessage,
    ConfirmDraw,
    ConfirmDiscountDraw,
    ConfirmClaim,
    YouTubeVideo,
    RulesAndTutorial,
    NotifyReward,
    HistoryRecord,
    NotifyClaim,
    NotifyRedeem,
    EnemyInfo,
    RemindHistory,
    NotifyJackpot,
    NotifyClaimHint,
  },

  setup() {
    const { modalState } = useModal();
    const currentModals = computed(() => modalState.currentModals);

    return { currentModals };
  },
});
