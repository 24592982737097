import api from './api';
import {
  ErrorMessageResponse,
  ProfileResponse,
  DrawResponse,
  MilestoneRedeemResponse,
  HistoryClaimResponse,
  AgreeResponse,
  CurrentDraw,
} from '@/types';

if (process.env.NODE_ENV === 'development') {
  // eslint-disable-next-line global-require
  // eslint-disable-next-line @typescript-eslint/no-var-requires
  const mock = require('./mock').default;
  mock();
}

export default {
  getProfile() {
    return api<ProfileResponse | ErrorMessageResponse>('get', '/profile');
  },
  postDraw({ drawType, prop, coupon }: CurrentDraw) {
    const drawData: CurrentDraw = prop ? { drawType, prop } : { drawType, coupon }

    return api<DrawResponse | ErrorMessageResponse>('post', '/draw', drawData);
  },
  postHistoryClaim({ rewardIds }: { rewardIds: number[] }) {
    return api<HistoryClaimResponse | ErrorMessageResponse>('post', '/history/claim', {
      rewardIds,
    });
  },
  postMilestoneRedeem({ milestoneId }: { milestoneId: number }) {
    return api<MilestoneRedeemResponse | ErrorMessageResponse>(
      'post',
      '/milestone/redeem',
      { milestoneId },
    );
  },
  postAgree(isAgreed: boolean) {
    return api<AgreeResponse | ErrorMessageResponse>('post', '/agree', { isAgreed });
  }
};
