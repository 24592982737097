import { createApp } from 'vue';
import * as Sentry from '@sentry/browser';
import * as Integrations from '@sentry/integrations';
import App from '@ui/App.vue';
import i18n from '@/i18n';
import '@/preloader';
import '@ui/styles/global.scss';
import setupLayout from '@/layout';
setupLayout();

const app = createApp(App)
  .use(i18n)
  .mount('#app');

if (process.env.NODE_ENV === 'production') {
  Sentry.init({
    dsn:
      'https://df1a9b2f2a8d469a88223f978d123eea@pub.sentry.web.garenanow.com/204',
    // eslint-disable-next-line @typescript-eslint/ban-ts-ignore
    // @ts-ignore
    integrations: [new Integrations.Vue({ Vue: app, attachProps: true })],
  });
}
