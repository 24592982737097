
import ButtonRules from './ButtonRules.vue';
import Balances from './Balances.vue';

export default {
  components: {
    ButtonRules,
    Balances,
  },
};
