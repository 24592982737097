import { reactive, readonly } from 'vue';
import service from '@/services';
import { initStorage, getStorage } from '@/storageUtils';
import { apiHandler } from '@/store/utils';
import { useHistory, useMilestone, useDraw, useModal } from '@/store';
import {
  LocalStatusEnum,
  ModalEnum,
  ProfileResponse,
  Enemy,
  Balance,
} from '@/types';

interface ProfileState {
  tutorialNum: number;
  uniqueId: string;
  youtubeVideoId: string;
  nickname: string;
  balance: Balance;
  enemies: Enemy[];
  grandPrizePoint: number;
  selectEnemy: Enemy;
  isShowThaiAgreement: boolean;
}

const profileState = reactive<ProfileState>({
  tutorialNum: 0,
  uniqueId: '',
  youtubeVideoId: '',
  nickname: '',
  balance: {
    coupon: 0,
    prop: 0,
  },
  enemies: [],
  grandPrizePoint: 0,
  selectEnemy: {} as Enemy,
  isShowThaiAgreement: false,
});

function setBalance(balance: Balance) {
  profileState.balance = balance || {};
}

function setSelectEnemy(enemy: Enemy) {
  profileState.selectEnemy = enemy || ({} as Enemy);
}

async function getProfile() {
  const response = await apiHandler(service.getProfile);

  if (response) {
    const { setHistory } = useHistory();
    const { setMilestone } = useMilestone();
    const { setDrawState } = useDraw();
    const { setModal } = useModal();
    const {
      history,
      milestones,
      priceInfo,
      maxSelectedItemCount,
      collectiveDrawCount,
      uniqueId,
      fanPoint,
      isConfirmed,
    } = response;

    initStorage(uniqueId);
    for (const key in profileState) {
      if (response[key as keyof ProfileResponse]) {
        Object.assign(profileState, {
          [key]: response[key as keyof ProfileResponse],
        });
      }
    }
    setDrawState({ priceInfo, collectiveDrawCount });
    setHistory({ history, maxSelectedItemCount });
    setMilestone({ milestones, fanPoint });

    if (!isConfirmed && process.env.LOCALE === 'th') {
      profileState.isShowThaiAgreement = true;
      return;
    }

    if (!getStorage(LocalStatusEnum.IS_VIDEO_PLAYED)) {
      setModal(ModalEnum.YOUTUBE_VIDEO);
    }
  }
}

async function postAgreeDataAnalysis(isAgree: boolean) {
  const response = await apiHandler(() => service.postAgree(isAgree));

  if (response) {
    const { setModal } = useModal();

    profileState.isShowThaiAgreement = false;
    if (!getStorage(LocalStatusEnum.IS_VIDEO_PLAYED)) {
      setModal(ModalEnum.YOUTUBE_VIDEO);
    }
  }
}

export default () => {
  return {
    profileState: readonly(profileState),
    getProfile,
    setBalance,
    setSelectEnemy,
    postAgreeDataAnalysis,
  };
};
