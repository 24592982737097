
import { computed } from 'vue';
import { useProfile, useModal, useMilestone } from '@/store';
import { ModalEnum, Enemy } from '@/types';
import ButtonVideo from './ButtonVideo.vue';
import DrawSection from './DrawSection.vue';

export default {
  components: {
    DrawSection,
    ButtonVideo,
  },
  setup() {
    const { profileState, setSelectEnemy } = useProfile();
    const { milestoneState } = useMilestone();
    const { setModal } = useModal();
    const enemies = computed(() => profileState.enemies);
    const isJackpotRedeemed = computed(() => milestoneState.isJackpotRedeemed);
    const grandPrizePoint = computed(() => profileState.grandPrizePoint);

    function handleClickEnemy(enemy: Enemy) {
      setSelectEnemy(enemy);
      setModal(ModalEnum.ENEMY_INFO);
    }
    return {
      grandPrizePoint,
      enemies,
      isJackpotRedeemed,
      setSelectEnemy,
      handleClickEnemy,
    };
  },
};
