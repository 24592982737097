/*
 * image preloader
 */
import { useGeneral } from '@/store';

/* eslint-disable global-require */
const images: string[] = [
  `${process.env.CDN}/animation/animation-01.jpg`,
  `${process.env.CDN}/animation/animation-cheers.png`,
  `${process.env.CDN}/animation/animation-skip.png`,
  `${process.env.CDN}/animation/animation-02.jpg`,
  `${process.env.CDN}/animation/animation-frame.gif`,
  `${process.env.CDN}/mainpage/mainpage-background.jpg`,
  `${process.env.CDN}/mainpage/mainpage-content-hero1-box.png`,
  `${process.env.CDN}/mainpage/mainpage-content-hero1.png`,
  `${process.env.CDN}/mainpage/mainpage-bar-bg.png`,
  `${process.env.CDN}/mainpage/mainpage-content-hero2-box.png`,
  `${process.env.CDN}/mainpage/mainpage-bar-frame-default.png`,
  `${process.env.CDN}/mainpage/mainpage-content-hero2.png`,
  `${process.env.CDN}/mainpage/mainpage-bar-frame-gray.png`,
  `${process.env.CDN}/mainpage/mainpage-content-hero3-box.png`,
  `${process.env.CDN}/mainpage/mainpage-bar-frame-light.png`,
  `${process.env.CDN}/mainpage/mainpage-content-hero3.png`,
  `${process.env.CDN}/mainpage/mainpage-bar-gray.png`,
  `${process.env.CDN}/mainpage/mainpage-content-hero4-box.png`,
  `${process.env.CDN}/mainpage/mainpage-bar-jackpot.png`,
  `${process.env.CDN}/mainpage/mainpage-content-hero4.png`,
  `${process.env.CDN}/mainpage/mainpage-bar-light.png`,
  `${process.env.CDN}/mainpage/mainpage-content-hero5-box.png`,
  `${process.env.CDN}/mainpage/mainpage-bar-title.png`,
  `${process.env.CDN}/mainpage/mainpage-content-hero5.png`,
  `${process.env.CDN}/mainpage/mainpage-btn-10draw-default.png`,
  `${process.env.CDN}/mainpage/mainpage-content-hero6-box.png`,
  `${process.env.CDN}/mainpage/mainpage-btn-10draw-discount.png`,
  `${process.env.CDN}/mainpage/mainpage-content-hero6.png`,
  `${process.env.CDN}/mainpage/mainpage-btn-1draw-default.png`,
  `${process.env.CDN}/mainpage/mainpage-content.png`,
  `${process.env.CDN}/mainpage/mainpage-btn-1draw-discount.png`,
  `${process.env.CDN}/mainpage/mainpage-coupons.png`,
  `${process.env.CDN}/mainpage/mainpage-btn-1draw-props.png`,
  `${process.env.CDN}/mainpage/mainpage-logo.png`,
  `${process.env.CDN}/mainpage/mainpage-btn-history.png`,
  `${process.env.CDN}/mainpage/mainpage-props.png`,
  `${process.env.CDN}/mainpage/mainpage-btn-vdo.png`,
  `${process.env.CDN}/mainpage/mainpage-rulesicon.png`,
  `${process.env.CDN}/mainpage/mainpage-cheers.png`,
  `${process.env.CDN}/mainpage/mainpage-token.png`,
  `${process.env.CDN}/popup/popup-bg01.png`,
  `${process.env.CDN}/popup/popup-limited-name.png`,
  `${process.env.CDN}/popup/popup-bg02.png`,
  `${process.env.CDN}/popup/popup-limited-prize.png`,
  `${process.env.CDN}/popup/popup-btn-cancel.png`,
  `${process.env.CDN}/popup/popup-props.png`,
  `${process.env.CDN}/popup/popup-btn-confirm.png`,
  `${process.env.CDN}/popup/popup-success-bg01.png`,
  `${process.env.CDN}/popup/popup-btn-quickchoose.png`,
  `${process.env.CDN}/popup/popup-success-bg02.png`,
  `${process.env.CDN}/popup/popup-btn-send.png  `,
  `${process.env.CDN}/popup/popup-success-hero-loss.png`,
  `${process.env.CDN}/popup/popup-btn-sendgray.png`,
  `${process.env.CDN}/popup/popup-success-hero-win.png`,
  `${process.env.CDN}/popup/popup-click.png`,
  `${process.env.CDN}/popup/popup-success-hero1.png`,
  `${process.env.CDN}/popup/popup-clicked.png`,
  `${process.env.CDN}/popup/popup-success-hero2.png`,
  `${process.env.CDN}/popup/popup-close.png`,
  `${process.env.CDN}/popup/popup-success-hero3.png`,
  `${process.env.CDN}/popup/popup-coupons.png`,
  `${process.env.CDN}/popup/popup-success-hero4.png`,
  `${process.env.CDN}/popup/popup-eventrules-bg.png`,
  `${process.env.CDN}/popup/popup-success-hero5.png`,
  `${process.env.CDN}/popup/popup-frame-click.png`,
  `${process.env.CDN}/popup/popup-success-hero6.png`,
  `${process.env.CDN}/popup/popup-frame-clicked.png`,
  `${process.env.CDN}/popup/popup-success-title.png`,
  `${process.env.CDN}/popup/popup-frame-gray.png`,
  `${process.env.CDN}/popup/popup-title-congrats.png`,
  `${process.env.CDN}/popup/popup-frame-light.png`,
  `${process.env.CDN}/popup/popup-title-history.png`,
  `${process.env.CDN}/popup/popup-frame-mask.png`,
  `${process.env.CDN}/popup/popup-title-notice.png`,
  `${process.env.CDN}/popup/popup-frame2.png`,
  `${process.env.CDN}/popup/popup-title-video.png`,
  `${process.env.CDN}/popup/popup-history.png`,
  `${process.env.CDN}/popup/popup-tutorial-arrow.png`,
  `${process.env.CDN}/popup/popup-limited-bg01.png`,
  `${process.env.CDN}/popup/popup-tutorial-bg.png`,
  `${process.env.CDN}/popup/popup-limited-bg02.png`,
  `${process.env.CDN}/popup/popup-vdo-bg.png`,
  `${process.env.CDN}/popup/popup-limited-character.png`,
  `${process.env.CDN}/popup/popup-vdo-icon.png`,
];

switch (process.env.VERSION) {
  case 'cheers': {
    const { setLoadedImgPercentage } = useGeneral();
    const totalImgCount = images.length;
    let count = 0;

    if (totalImgCount === 0) {
      setLoadedImgPercentage(100);
      break;
    }

    images.forEach((image) => {
      const img = new Image();
      let timer: ReturnType<typeof setTimeout>;
      img.onload = () => {
        if (img.complete) {
          clearTimeout(timer);
          count++;
          setLoadedImgPercentage(Math.ceil(count / totalImgCount * 100));
        }
      };

      img.onerror = () => {
        timer = setTimeout(() => {
          img.src = image;
        }, 1000);
      };

      img.src = image;
    });

    break;
  }
  default:
    images.forEach((image) => {
      new Image().src = image;
    });
    break;
}
