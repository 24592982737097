import { reactive, readonly } from 'vue';
import service from '@/services';
import { apiHandler } from '@/store/utils';
import { useModal } from '@/store';
import { ModalEnum, MilestoneItem } from '@/types';

interface MilestoneState {
  milestones: MilestoneItem[];
  fanPoint: number;
  redeemItem: MilestoneItem | null;
  isJackpotRedeemed: boolean;
  currentRedeemJackpot: boolean;
}

const milestoneState = reactive<MilestoneState>({
  milestones: [],
  fanPoint: 0,
  redeemItem: null,
  isJackpotRedeemed: false,
  currentRedeemJackpot: false,
});

function setMilestone({
  milestones,
  fanPoint,
}: {
  milestones: MilestoneItem[];
  fanPoint?: number;
}) {
  milestoneState.milestones = milestones.sort(
    (a, b) => b.pointThreshold - a.pointThreshold,
  );
  if (fanPoint) {
    milestoneState.fanPoint = fanPoint;
  }
  if (milestoneState.milestones[0]?.redeemed) {
    milestoneState.isJackpotRedeemed = true;
  }
}

async function postMilestoneRedeem(milestoneId: number, isJackpot = false) {
  const response = await apiHandler(() =>
    service.postMilestoneRedeem({ milestoneId }),
  );

  if (response) {
    const { setModal } = useModal();
    const { milestones } = response;
    const redeemItem = milestones.reduce(
      (pre, cur) => (cur.id === milestoneId ? cur : pre),
      {} as MilestoneItem,
    );
    setMilestone({ milestones });
    milestoneState.redeemItem = redeemItem;
    milestoneState.currentRedeemJackpot = isJackpot;
    setModal(ModalEnum.NOTIFY_REDEEM);
  }
}

export default () => {
  return {
    milestoneState: readonly(milestoneState),
    postMilestoneRedeem,
    setMilestone,
  };
};
