function getParam(paramName: string, url: string = window.location.href) {
  const name = paramName.replace(/[[]]/g, '\\$&');
  const regex = new RegExp(`[?&]${name}(=([^&#]*)|&|#|$)`);
  const results = regex.exec(url);
  if (!results) return null;
  if (!results[2]) return '';
  return decodeURIComponent(results[2].replace(/\+/g, ' '));
}

function camelizeStr(str: string): string {
  return str.replace(/[_.-](\w|$)/g, (_, x) => x.toUpperCase());
}

function snakifyStr(str: string): string {
  return str.replace(/(?:^|\.?)([A-Z])/g, (_, x) => `_${x.toLowerCase()}`);
}

function deleteUrlParam(name: string) {
  const query = window.location.search.substr(1);
  if (query.indexOf(name) > -1) {
    const obj: any = {};
    const arr = query.split('&');
    for (let i = 0; i < arr.length; i++) {
      const pair = arr[i].split('=');
      obj[pair[0]] = pair[1];
    }
    delete obj[name];
    const url = JSON.stringify(obj)
      .replace(/["{}]/g, '')
      .replace(/:/g, '=')
      .replace(/,/g, '&');

    const remainingQuery = url ? `?${url}` : '';

    window.history.replaceState(
      {},
      '',
      `${window.location.origin}${window.location.pathname}${remainingQuery}`
    );
  }
}

type ConvertFunc = (k: string) => string;
export type AnyJson = boolean | number | string | null | JsonArray | JsonMap;
interface JsonMap {
  [key: string]: AnyJson;
}
type JsonArray = Array<AnyJson>;

function convertCase(convertFunc: ConvertFunc) {
  function converter(thing: AnyJson): AnyJson {
    if (Array.isArray(thing)) {
      return thing.map((i) => converter(i));
    }
    if (thing !== null && typeof thing === 'object') {
      const newObj: JsonMap = {};
      Object.keys(thing).forEach((k: string) => {
        newObj[convertFunc(k)] = converter(thing[k]);
      });
      return newObj;
    }
    return thing;
  }
  return converter;
}

export function delay(ms: number) {
  return new Promise((resolve) => setTimeout(resolve, ms));
}

export default {
  getParam,
  camelizeStr,
  snakifyStr,
  delay,
  camelizeKeys: convertCase(camelizeStr),
  snakifyKeys: convertCase(snakifyStr),
  deleteUrlParam,
};
