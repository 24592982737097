
import { computed, watch } from 'vue';
import { ModalEnum, FullScreenAnimationEnum } from '@/types';
import { useModal } from '@/store';

export default {
  setup() {
    const { modalState, setFullScreenAnimation, setModal } = useModal();
    const isShowAnimation = computed(
      () =>
        modalState.currentFullScreenAnimation ===
        FullScreenAnimationEnum.JACKPOT,
    );
    let animationTimer: ReturnType<typeof setTimeout>;

    function handleClickSkip() {
      setFullScreenAnimation(null);
      setModal(ModalEnum.NOTIFY_REWARD);
    }

    watch(isShowAnimation, (val, oldVal) => {
      if (val) {
        animationTimer = setTimeout(() => {
          setFullScreenAnimation(null);
          setModal(ModalEnum.NOTIFY_REWARD);
        }, 4000);
        return;
      }
      if (!val && oldVal) {
        clearTimeout(animationTimer);
      }
    });

    return {
      isShowAnimation,
      handleClickSkip,
    };
  },
};
