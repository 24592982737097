
import { ModalTypeEnum } from '@/types';

export default {
  props: {
    isCancelButtonVisible: {
      type: Boolean,
      default: false,
    },
    isConfirmButtonVisible: {
      type: Boolean,
      default: true,
    },
    isCloseButtonVisible: {
      type: Boolean,
      default: false,
    },
    type: {
      type: Number,
      default: 0,
    },
    title: {
      type: String,
    },
    cancelText: {
      type: String,
      default: 'cancel',
    },
    confirmText: {
      type: String,
      default: 'confirm',
    },
  },
  setup() {
    return {
      ModalTypeEnum,
    };
  },
};
