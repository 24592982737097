
import { computed } from 'vue';
import { useMilestone, useModal } from '@/store';
import { ModalEnum } from '@/types';
import Modal from '../Base/Modal.vue';

export default {
  components: {
    Modal,
  },
  setup() {
    const { milestoneState } = useMilestone();
    const { setModal } = useModal();
    const redeemItem = computed(() => milestoneState.redeemItem);
    const currentRedeemJackpot = computed(
      () => milestoneState.currentRedeemJackpot,
    );

    function handleCloseModal() {
      setModal(null);
      if (milestoneState.currentRedeemJackpot) {
        setModal(ModalEnum.NOTIFY_JACKPOT);
      }
    }

    return {
      redeemItem,
      currentRedeemJackpot,
      handleCloseModal,
    };
  },
};
