
import { toRefs, computed } from 'vue';
import { useDraw, useModal } from '@/store';
import { ModalTypeEnum, ModalEnum } from '@/types';
import Modal from '../Base/Modal.vue';

export default {
  components: {
    Modal,
  },
  setup() {
    const { drawState } = useDraw();
    const { setModal } = useModal();
    const {
      encounteredEnemies,
      rewards,
      hitJackpot,
      currentDrawPoint,
    } = toRefs(drawState);
    const isCollective = computed(() => drawState.rewards.length > 1);
    const isShowBattleList = computed(
      () => !drawState.hitJackpot || isCollective.value,
    );

    function handleCloseModal() {
      setModal(null);
      if (drawState.hitJackpot) {
        setModal(ModalEnum.NOTIFY_JACKPOT);
        return;
      }
      if (drawState.isFirstDraw) {
        setModal(ModalEnum.REMIND_HISTORY);
      }
    }
    return {
      currentDrawPoint,
      encounteredEnemies,
      rewards,
      hitJackpot,
      isCollective,
      isShowBattleList,
      handleCloseModal,
      ModalTypeEnum,
    };
  },
};
