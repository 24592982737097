
import { ModalEnum } from '@/types';
import { useModal } from '@/store';

export default {
  setup() {
    const { setModal } = useModal();

    function handleClick() {
      setModal(ModalEnum.YOUTUBE_VIDEO);
    }

    return {
      handleClick,
    };
  },
};
