
import { useModal } from '@/store';
import { ModalEnum } from '@/types';

export default {
  setup() {
    const { setModal } = useModal();

    function handleClickHistory() {
      setModal(ModalEnum.HISTORY_RECORD);
    }
    return {
      handleClickHistory,
    };
  },
};
