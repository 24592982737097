
import { useProfile } from '@/store';

export default {
  name: 'ThaiAgreement',
  props: { show: { type: Boolean, default: false } },
  setup() {
    const { postAgreeDataAnalysis } = useProfile();

    return {
      postAgreeDataAnalysis,
    };
  },
};
