
import { toRefs, computed } from 'vue';
import { getStorage } from '@/storageUtils';
import { useProfile, useDraw, useModal, useGeneral } from '@/store';
import {
  ModalEnum,
  LocalStatusEnum,
  DrawTypeEnum,
  ErrorTypesEnum,
} from '@/types';

const {
  IS_SKIP_PROP_DRAW_CONFIRM,
  IS_SKIP_DRAW_CONFIRM,
  IS_SKIP_COLLECTIVE_DRAW_CONFIRM,
} = LocalStatusEnum;
const { SINGLE, COLLECTIVE } = DrawTypeEnum;
const { CONFIRM_DISCOUNT_DRAW, CONFIRM_DRAW } = ModalEnum;

export default {
  setup() {
    const { profileState } = useProfile();
    const { handleExpectedError } = useGeneral();
    const { drawState, postDraw, setCurrentDraw } = useDraw();
    const { setModal } = useModal();
    const {
      singleDrawPrice,
      collectiveDrawPrice,
      singleDrawDiscountedPrice,
      collectiveDrawDiscountPrice,
      propDrawPrice,
    } = toRefs(drawState);
    const isProps = computed(
      () => propDrawPrice.value > 0 && profileState.balance.prop > 0,
    );

    function handleClickSingleDraw() {
      if (
        !isProps.value &&
        profileState.balance.coupon < singleDrawDiscountedPrice.value
      ) {
        handleExpectedError({
          error: 'ERROR__LACK_OF_CP',
          code: ErrorTypesEnum.ERROR__INSUFFICIENT_BALANCE,
        });
        return;
      }

      const isSkip = getStorage(
        isProps.value ? IS_SKIP_PROP_DRAW_CONFIRM : IS_SKIP_DRAW_CONFIRM,
      );

      setCurrentDraw({
        drawType: SINGLE,
        coupon: singleDrawDiscountedPrice.value,
        prop: isProps.value ? propDrawPrice.value : undefined,
      });
      if (
        singleDrawPrice.value !== singleDrawDiscountedPrice.value &&
        !isProps.value
      ) {
        setModal(CONFIRM_DISCOUNT_DRAW);
        return;
      }
      isSkip ? postDraw() : setModal(CONFIRM_DRAW);
    }

    function handleClickCollectiveDraw() {
      if (profileState.balance.coupon < collectiveDrawDiscountPrice.value) {
        handleExpectedError({
          error: 'ERROR__LACK_OF_CP',
          code: ErrorTypesEnum.ERROR__INSUFFICIENT_BALANCE,
        });
        return;
      }
      const isSkip = getStorage(IS_SKIP_COLLECTIVE_DRAW_CONFIRM);

      setCurrentDraw({
        drawType: COLLECTIVE,
        coupon: collectiveDrawDiscountPrice.value,
      });
      if (collectiveDrawPrice.value !== collectiveDrawDiscountPrice.value) {
        setModal(CONFIRM_DISCOUNT_DRAW);
        return;
      }
      isSkip ? postDraw() : setModal(CONFIRM_DRAW);
    }

    return {
      singleDrawPrice,
      collectiveDrawPrice,
      singleDrawDiscountedPrice,
      collectiveDrawDiscountPrice,
      propDrawPrice,
      isProps,
      handleClickSingleDraw,
      handleClickCollectiveDraw,
    };
  },
};
