
import { computed } from 'vue';
import { useHistory, useModal } from '@/store';
import { ModalEnum } from '@/types';
import Modal from '../Base/Modal.vue';

export default {
  components: {
    Modal,
  },
  setup() {
    const { historyState, setCurrentClaim } = useHistory();
    const { setModal } = useModal();
    const currentClaim = computed(() => historyState.currentClaim);

    function handleCloseModal() {
      setModal(null);
      setCurrentClaim([]);
      if (historyState.showHint) {
        setModal(ModalEnum.NOTIFY_CLAIM_HINT);
      }
    }

    return {
      historyState,
      currentClaim,
      handleCloseModal,
    };
  },
};
