import { createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, normalizeClass as _normalizeClass, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-12dd5af3"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "card" }
const _hoisted_2 = ["innerHTML"]
const _hoisted_3 = ["innerHTML"]
const _hoisted_4 = ["innerHTML"]
const _hoisted_5 = ["innerHTML"]
const _hoisted_6 = { class: "card__buttons" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass(["modal-thai-agreement", { show: $props.show }])
  }, [
    _createElementVNode("div", _hoisted_1, [
      _createElementVNode("div", {
        class: "card__title",
        innerHTML: _ctx.$t('CONSENT__TITLE')
      }, null, 8, _hoisted_2),
      _createElementVNode("div", {
        class: "card__game",
        innerHTML: _ctx.$t('CONSENT__GAME_NAME')
      }, null, 8, _hoisted_3),
      _createElementVNode("div", {
        class: "card__event",
        innerHTML: _ctx.$t('CONSENT__EVENT_NAME')
      }, null, 8, _hoisted_4),
      _createElementVNode("div", {
        class: "card__content",
        innerHTML: _ctx.$t('CONSENT__CONTENT')
      }, null, 8, _hoisted_5),
      _createElementVNode("div", _hoisted_6, [
        _createElementVNode("button", {
          class: "btn btn--reject",
          onClick: _cache[0] || (_cache[0] = ($event: any) => ($setup.postAgreeDataAnalysis(false)))
        }, _toDisplayString(_ctx.$t('CONSENT__DISAGREE')), 1),
        _createElementVNode("button", {
          class: "btn btn--accept",
          onClick: _cache[1] || (_cache[1] = ($event: any) => ($setup.postAgreeDataAnalysis(true)))
        }, _toDisplayString(_ctx.$t('CONSENT__AGREE')), 1)
      ])
    ])
  ], 2))
}