
import { computed } from 'vue';
import { useMilestone, useModal } from '@/store';
import { MilestoneTypeEnum, ModalEnum } from '@/types';
import MilestoneItem from './MilestoneItem.vue';

function getProgress(point: number, thresholds: number[]): number {
  const totalThresholds = thresholds.length;
  let pre = 0;
  for (let i = totalThresholds - 1; i > 0; i--) {
    if (pre < point && point <= thresholds[i]) {
      return (
        ((totalThresholds - i - 1) / totalThresholds +
          (point - pre) / (thresholds[i] - pre) / totalThresholds) *
        100
      );
    }
    pre = thresholds[i];
  }
  return 100;
}

export default {
  components: {
    MilestoneItem,
  },
  setup() {
    const { milestoneState, postMilestoneRedeem } = useMilestone();
    const { setModal } = useModal();
    const milestones = computed(() =>
      milestoneState.milestones.map(
        ({ id, image, pointThreshold, redeemed, name }) => {
          const targetStatus =
            milestoneState.fanPoint >= pointThreshold
              ? MilestoneTypeEnum.REACH_TARGET
              : MilestoneTypeEnum.NOT_REACH_TARGET;
          return {
            id,
            image,
            name,
            target: pointThreshold,
            status: redeemed ? MilestoneTypeEnum.REDEEM : targetStatus,
          };
        },
      ),
    );
    const fanPoint = computed(() => milestoneState.fanPoint);
    const progress = computed(() =>
      milestoneState.fanPoint > 0
        ? Math.floor(
            getProgress(
              milestoneState.fanPoint,
              milestoneState.milestones.map((item) => item.pointThreshold),
            ),
          )
        : 0,
    );

    function handleClickMilestone(
      milestoneId: number,
      status: MilestoneTypeEnum,
      isJackpot: boolean,
    ) {
      if (status === MilestoneTypeEnum.REACH_TARGET) {
        postMilestoneRedeem(milestoneId, isJackpot);
        return;
      }
      if (isJackpot && status === MilestoneTypeEnum.REDEEM) {
        setModal(ModalEnum.NOTIFY_JACKPOT);
        return;
      }
    }

    return {
      fanPoint,
      milestones,
      progress,
      handleClickMilestone,
      MilestoneTypeEnum,
    };
  },
};
