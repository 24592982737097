
import { useModal } from '@/store';
import JackpotAnimation from './JackpotAnimation.vue';
import NormalRewardAnimation from './NormalRewardAnimation.vue';

export default {
  components: {
    JackpotAnimation,
    NormalRewardAnimation,
  },
  setup() {
    const { modalState } = useModal();

    return {
      modalState,
    };
  },
};
