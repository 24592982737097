import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "info-block" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ButtonRules = _resolveComponent("ButtonRules")!
  const _component_Balances = _resolveComponent("Balances")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_ButtonRules),
    _createVNode(_component_Balances)
  ]))
}