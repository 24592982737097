
import { ref, computed } from 'vue';
import { useDraw, useModal } from '@/store';
import { setStorage } from '@/storageUtils';
import { LocalStatusEnum, DrawTypeEnum } from '@/types';
import Modal from '../Base/Modal.vue';

const {
  IS_SKIP_COLLECTIVE_DRAW_CONFIRM,
  IS_SKIP_DRAW_CONFIRM,
  IS_SKIP_PROP_DRAW_CONFIRM,
} = LocalStatusEnum;
const { SINGLE } = DrawTypeEnum;

export default {
  components: {
    Modal,
  },
  setup() {
    const isSkip = ref(false);
    const { setModal } = useModal();
    const { drawState, postDraw } = useDraw();
    const currentDraw = computed(() => drawState.currentDraw);
    const collectiveDrawCount = computed(() => drawState.collectiveDrawCount);

    function handleConfirm() {
      const skipKey =
        drawState.currentDraw.drawType === SINGLE
          ? drawState.currentDraw.prop
            ? IS_SKIP_PROP_DRAW_CONFIRM
            : IS_SKIP_DRAW_CONFIRM
          : IS_SKIP_COLLECTIVE_DRAW_CONFIRM;
      setStorage(skipKey, isSkip.value);
      setModal(null);
      postDraw();
    }

    return {
      isSkip,
      currentDraw,
      collectiveDrawCount,
      setModal,
      handleConfirm,
      DrawTypeEnum,
    };
  },
};
