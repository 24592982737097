
import { ref, computed } from 'vue';
import { setStorage } from '@/storageUtils';
import { LocalStatusEnum } from '@/types';
import { useHistory, useModal } from '@/store';
import Modal from '../Base/Modal.vue';

export default {
  components: {
    Modal,
  },
  setup() {
    const isSkip = ref(false);
    const { historyState, postHistoryClaim } = useHistory();
    const { setModal } = useModal();
    const currentClaim = computed(() => historyState.currentClaim);

    function handleConfirm() {
      setStorage(LocalStatusEnum.IS_SKIP_SEND_CONFIRM, isSkip.value);
      setModal(null);
      postHistoryClaim();
    }
    return {
      isSkip,
      currentClaim,
      handleConfirm,
      setModal,
    };
  },
};
