import { reactive, readonly } from 'vue';
import { ModalName } from '@/types';

interface ModalState {
  currentModals: ModalName[];
  currentFullScreenAnimation: string | null;
}

const state = reactive<ModalState>({
  currentModals: [],
  currentFullScreenAnimation: null,
});

function setModal(currentModal: ModalName | null) {
  if (currentModal) {
    state.currentModals.push(currentModal);
  } else {
    state.currentModals.pop();
  }
}

function setFullScreenAnimation(currentFullScreenAnimation: string | null) {
  state.currentFullScreenAnimation = currentFullScreenAnimation;
}

export default () => {
  return {
    modalState: readonly(state),
    setModal,
    setFullScreenAnimation,
  };
};
