import { reactive, readonly } from 'vue';
import service from '@/services';
import { apiHandler } from '@/store/utils';
import { useHistory, useMilestone, useProfile, useModal } from '@/store';
import {
  FullScreenAnimationEnum,
  DrawTypeEnum,
  PriceInfo,
  RewardItem,
  EncounteredEnemy,
  CurrentDraw,
} from '@/types';

interface DrawState {
  singleDrawPrice: number;
  singleDrawDiscountedPrice: number;
  collectiveDrawPrice: number;
  collectiveDrawDiscountPrice: number;
  propDrawPrice: number;
  collectiveDrawCount: number;
  encounteredEnemies: EncounteredEnemy[];
  rewards: RewardItem[];
  hitJackpot: boolean;
  currentDraw: CurrentDraw;
  currentDrawPoint: number;
  isFirstDraw: boolean;
}

const drawState = reactive<DrawState>({
  singleDrawPrice: 0,
  collectiveDrawPrice: 0,
  singleDrawDiscountedPrice: 0,
  collectiveDrawDiscountPrice: 0,
  propDrawPrice: 0,
  collectiveDrawCount: 0,
  encounteredEnemies: [],
  rewards: [],
  hitJackpot: false,
  currentDraw: {
    drawType: DrawTypeEnum.SINGLE,
  },
  currentDrawPoint: 0,
  isFirstDraw: false,
});

async function postDraw() {
  const response = await apiHandler(() =>
    service.postDraw(drawState.currentDraw),
  );

  if (response) {
    const {
      hitJackpot,
      history,
      milestones,
      balance,
      encounteredEnemies,
      rewards,
      fanPoint,
      isFirstDraw,
    } = response;
    const {
      collectiveDrawDiscountPrice,
      collectiveDrawPrice,
      singleDrawDiscountedPrice,
      singleDrawPrice,
    } = drawState;
    const { setBalance } = useProfile();
    const { setHistory } = useHistory();
    const { milestoneState, setMilestone } = useMilestone();
    const { setFullScreenAnimation } = useModal();

    drawState.encounteredEnemies = encounteredEnemies;
    drawState.rewards = rewards;
    drawState.hitJackpot = hitJackpot;
    drawState.currentDrawPoint = fanPoint - milestoneState.fanPoint;
    drawState.isFirstDraw = isFirstDraw;
    setBalance(balance);
    setHistory({ history });
    setMilestone({ milestones, fanPoint });

    if (!drawState.currentDraw.prop) {
      if (collectiveDrawDiscountPrice !== collectiveDrawPrice) {
        drawState.collectiveDrawDiscountPrice = collectiveDrawPrice;
      }
      if (singleDrawDiscountedPrice !== singleDrawPrice) {
        drawState.singleDrawDiscountedPrice = singleDrawPrice;
      }
    }

    if (hitJackpot) {
      setFullScreenAnimation(FullScreenAnimationEnum.JACKPOT);
    } else {
      setFullScreenAnimation(FullScreenAnimationEnum.NORMAL);
    }
  }
}

function setDrawState({
  priceInfo,
  collectiveDrawCount,
}: {
  priceInfo: PriceInfo;
  collectiveDrawCount: number;
}) {
  const {
    singleDraw: {
      price: singleDrawPrice,
      discountPrice: singleDrawDiscountedPrice,
      prop: propDrawPrice,
    },
    collectiveDraw: {
      price: collectiveDrawPrice,
      discountPrice: collectiveDrawDiscountPrice,
    },
  } = priceInfo;
  drawState.singleDrawPrice = singleDrawPrice;
  drawState.singleDrawDiscountedPrice = singleDrawDiscountedPrice;
  drawState.collectiveDrawPrice = collectiveDrawPrice;
  drawState.collectiveDrawDiscountPrice = collectiveDrawDiscountPrice;
  drawState.propDrawPrice = propDrawPrice;
  drawState.collectiveDrawCount = collectiveDrawCount;
}

function setCurrentDraw({ drawType, prop, coupon }: CurrentDraw) {
  const currentDraw = prop ? { drawType, prop } : { drawType, coupon };
  drawState.currentDraw = currentDraw;
}

export default () => {
  return {
    drawState: readonly(drawState),
    postDraw,
    setDrawState,
    setCurrentDraw,
  };
};
