import { reactive } from 'vue';
import { ModalEnum, ErrorMessageResponse } from '@/types';
import { useModal } from '@/store';

interface GeneralState {
  isLoading: boolean;
  errorMessage: string;
  errorCode: number;
  mailCount: number;
  loadedImgPercentage: number;
}

const generalState = reactive<GeneralState>({
  isLoading: false,
  errorMessage: '',
  errorCode: 0,
  mailCount: 0,
  loadedImgPercentage: 0,
});

function setLoading(isLoading: boolean) {
  generalState.isLoading = isLoading;
}

function handleExpectedError({
  error,
  code = 0,
  mailCount,
}: ErrorMessageResponse) {
  const { setModal } = useModal();
  generalState.errorMessage = error;
  generalState.errorCode = code;

  if (error === 'ERROR__LACK_OF_MAILBOX_SPACE' && mailCount) {
    generalState.mailCount = mailCount;
  }

  setModal(ModalEnum.ERROR_MESSAGE);
}

function handleUnexpectedError({ error, code = 0 }: ErrorMessageResponse) {
  const { setModal } = useModal();
  generalState.errorMessage = error;
  generalState.errorCode = code;
  setModal(ModalEnum.ERROR_MESSAGE);
}

function setLoadedImgPercentage(loadedImgPercentage: number) {
  generalState.loadedImgPercentage = loadedImgPercentage;
}

export default () => {
  return {
    generalState,
    setLoading,
    handleExpectedError,
    handleUnexpectedError,
    setLoadedImgPercentage,
  };
};
