
import { ref, computed, onMounted, onUnmounted, defineComponent } from 'vue';

export default defineComponent({
  props: {
    className: {
      type: String,
      default: '',
    },
    progress: {
      type: Number,
      default: 0,
    },
    needActionAfterTransition: {
      type: Boolean,
      default: false,
    },
  },
  setup(props) {
    const loadingProgress = ref(0);
    const offset = computed(() => {
      if (props.progress) {
        return 590 - (props.progress / 100) * 590;
      }

      return 590 - (loadingProgress.value / 100) * 590;
    });

    let timer: ReturnType<typeof setTimeout>;
    function addLoadingProgress() {
      timer = setInterval(() => {
        loadingProgress.value += 30;
      }, 100);
    }

    onMounted(() => {
      if (!props.progress) {
        addLoadingProgress();
      }
    });
    onUnmounted(() => {
      clearTimeout(timer);
    });

    return {
      offset,
    };
  },
});
