
/* eslint-disable @typescript-eslint/camelcase */
import { reactive, onMounted } from 'vue';
import { setStorage, getStorage } from '@/storageUtils';
import { useProfile, useModal } from '@/store';
import { ModalEnum, LocalStatusEnum, ModalTypeEnum } from '@/types';
import Modal from '../Base/Modal.vue';

export default {
  components: {
    Modal,
  },
  setup() {
    const { setModal } = useModal();
    const { profileState } = useProfile();
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const modalState = reactive<{ player: any; isShowVideoBtn: boolean }>({
      player: null,
      isShowVideoBtn: true,
    });

    function onPlayerReady(event: {
      target: { playVideo: () => void; unMute: () => void };
    }) {
      event.target.playVideo();
      event.target.unMute();
    }

    function onPlayerStateChange(event: { data: number }) {
      modalState.isShowVideoBtn = event.data !== window.YT.PlayerState.PLAYING;
      if (event.data === window.YT.PlayerState.ENDED)
        modalState.player.stopVideo();
    }

    function initPlayer() {
      modalState.player = new window.YT.Player('player', {
        host: 'https://www.youtube.com',
        videoId: profileState.youtubeVideoId,
        playerVars: {
          fs: 0, // prevents the fullscreen button from displaying in the playe
          controls: 0, // whether the video player controls are displayed
          enablejsapi: 1, // enables the player to be controlled via IFrame Player API calls
          iv_load_policy: 3, // setting to 3 causes video annotations to not be shown by default
          autoplay: 1, // automatically start
          modestbranding: 1, // YouTube logo. 1 to prevent
          playsinline: 1, // play inline or fullscreen
        },
        events: {
          onReady: onPlayerReady,
          onStateChange: onPlayerStateChange,
        },
      });
    }

    function toggleVideo() {
      if (
        !modalState.player.getPlayerState ||
        typeof modalState.player.getPlayerState !== 'function'
      ) {
        return;
      }

      if (
        modalState.player.getPlayerState() !== window.YT.PlayerState.PLAYING
      ) {
        modalState.player.playVideo();
      } else {
        modalState.player.pauseVideo();
      }
      modalState.isShowVideoBtn = !modalState.isShowVideoBtn;
    }

    function handleCloseModal() {
      if (!getStorage(LocalStatusEnum.IS_VIDEO_PLAYED)) {
        setStorage(LocalStatusEnum.IS_VIDEO_PLAYED, true);
        setModal(null);
        setModal(ModalEnum.RULES_AND_TUTORIALS);
        return;
      }
      setModal(null);
    }

    onMounted(() => {
      if (window.YT) initPlayer();
      const tag = document.createElement('script');
      tag.src = 'https://www.youtube.com/iframe_api';
      const firstScriptTag = document.getElementsByTagName('script')[0];
      if (firstScriptTag.parentNode) {
        firstScriptTag.parentNode.insertBefore(tag, firstScriptTag);
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        (window as any).onYouTubePlayerAPIReady = initPlayer;
      }
    });

    return {
      modalState,
      toggleVideo,
      handleCloseModal,
      ModalTypeEnum,
    };
  },
};
