import { resolveComponent as _resolveComponent, createVNode as _createVNode, normalizeClass as _normalizeClass, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_JackpotAnimation = _resolveComponent("JackpotAnimation")!
  const _component_NormalRewardAnimation = _resolveComponent("NormalRewardAnimation")!

  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass([
      'full-screen-animation',
      $setup.modalState.currentFullScreenAnimation && 'full-screen-animation--show',
    ])
  }, [
    _createVNode(_component_JackpotAnimation),
    _createVNode(_component_NormalRewardAnimation)
  ], 2))
}