import { normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "draw-buttons" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("button", {
      class: _normalizeClass([
        'draw-btn',
        {
          'draw-btn--props': $setup.isProps,
          'draw-btn--discount':
            !$setup.isProps && $setup.singleDrawPrice !== $setup.singleDrawDiscountedPrice,
        },
      ]),
      onClick: _cache[0] || (_cache[0] = ($event: any) => ($setup.handleClickSingleDraw()))
    }, null, 2),
    _createElementVNode("button", {
      class: _normalizeClass([
        'draw-btn',
        'draw-btn--collective',
        {
          'draw-btn--discount':
            $setup.collectiveDrawDiscountPrice !== $setup.collectiveDrawPrice,
        },
      ]),
      onClick: _cache[1] || (_cache[1] = ($event: any) => ($setup.handleClickCollectiveDraw()))
    }, null, 2)
  ]))
}