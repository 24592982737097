import _useProfile from './Game/Profile';
import _useHistory from './Game/History';
import _useMilestone from './Game/Milestone'
import _useDraw from './Game/Draw'
import _useGeneral from './General';
import _useModal from './Modal';

export const useProfile = _useProfile 
export const useHistory = _useHistory;
export const useMilestone = _useMilestone;
export const useDraw = _useDraw;
export const useModal = _useModal;
export const useGeneral = _useGeneral;

// const { profileState } = _useProfile();
// const { historyState } = _useHistory();
// const { milestoneState } = _useMilestone();
// const { drawState } = _useDraw();
// const { modalState } = _useModal();
// const { generalState } = _useGeneral();

// watch([profileState], (_ ,newVal) => {
//   console.log('UPDATE profileState')
//   console.log(JSON.parse(JSON.stringify(newVal))[0]);
// })
// watch([historyState], (_ ,newVal) => {
//   console.log('UPDATE historyState')
//   console.log(JSON.parse(JSON.stringify(newVal))[0]);
// })
// watch([milestoneState], (_ ,newVal) => {
//   console.log('UPDATE milestoneState')
//   console.log(JSON.parse(JSON.stringify(newVal))[0]);
// })
// watch([drawState], (_ ,newVal) => {
//   console.log('UPDATE drawState')
//   console.log(JSON.parse(JSON.stringify(newVal))[0]);
// })
// watch([modalState], (_ ,newVal) => {
//   console.log('UPDATE modalState')
//   console.log(JSON.parse(JSON.stringify(newVal))[0]);
// })
// watch([generalState], (_ ,newVal) => {
//   console.log('UPDATE generalState')
//   console.log(JSON.parse(JSON.stringify(newVal))[0]);
// })