
import { ref, computed } from 'vue';
import { Swiper, SwiperSlide } from 'swiper/vue';
import SwiperCore, { Navigation, Pagination, Autoplay } from 'swiper';
import 'swiper/swiper-bundle.min.css';
import { useModal, useProfile } from '@/store';
import { ModalTypeEnum } from '@/types';
import Modal from '../Base/Modal.vue';

SwiperCore.use([Navigation, Pagination, Autoplay]);
export default {
  components: {
    Modal,
    Swiper,
    SwiperSlide,
  },
  setup() {
    const isRuleTab = ref(false);
    const { setModal } = useModal();
    const { profileState } = useProfile();
    const tutorialImage = computed(() => {
      return Array(profileState.tutorialNum)
        .fill(0)
        .map((_, i) => `${process.env.CDN}/popup/popup-tutorial0${i + 1}.png`);
    });

    return {
      isRuleTab,
      tutorialImage,
      setModal,
      ModalTypeEnum,
    };
  },
};
