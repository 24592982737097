export enum ModalTypeEnum {
  NORMAL = 0,
  LARGE = 1,
  CUSTOM = 2,
}

export enum ModalEnum {
  ERROR_MESSAGE = 'ErrorMessage',
  YOUTUBE_VIDEO = 'YouTubeVideo',
  RULES_AND_TUTORIALS = 'RulesAndTutorial',
  CONFIRM_DRAW = 'ConfirmDraw',
  CONFIRM_DISCOUNT_DRAW = 'ConfirmDiscountDraw',
  CONFIRM_CLAIM = 'ConfirmClaim',
  NOTIFY_REWARD = 'NotifyReward',
  HISTORY_RECORD = 'HistoryRecord',
  NOTIFY_CLAIM = 'NotifyClaim',
  NOTIFY_OUT_OF_SPACE = 'NotifyOutOfSpace',
  NOTIFY_REDEEM = 'NotifyRedeem',
  ENEMY_INFO = 'EnemyInfo',
  REMIND_HISTORY = 'RemindHistory',
  NOTIFY_JACKPOT = 'NotifyJackpot',
  NOTIFY_CLAIM_HINT = 'NotifyClaimHint',
}

export type ModalName = `${ModalEnum}`

export enum FullScreenAnimationEnum {
  JACKPOT = 'JackpotAnimation',
  NORMAL = 'NormalRewardAnimation',
}

export enum LocalStatusEnum {
  IS_VIDEO_PLAYED = 'IS_VIDEO_PLAYED',
  IS_SKIP_DRAW_CONFIRM = 'IS_SKIP_DRAW_CONFIRM',
  IS_SKIP_PROP_DRAW_CONFIRM = 'IS_SKIP_PROP_DRAW_CONFIRM',
  IS_SKIP_COLLECTIVE_DRAW_CONFIRM = 'IS_SKIP_COLLECTIVE_DRAW_CONFIRM',
  IS_SKIP_SEND_CONFIRM = 'IS_SKIP_SEND_CONFIRM',
}

export interface LocalStatus {
  IS_VIDEO_PLAYED?: boolean;
  IS_SKIP_DRAW_CONFIRM?: boolean;
  IS_SKIP_PROP_DRAW_CONFIRM?: boolean;
  IS_SKIP_COLLECTIVE_DRAW_CONFIRM?: boolean;
  IS_SKIP_SEND_CONFIRM?: boolean;
}



export enum DrawTypeEnum {
  SINGLE = 1,
  COLLECTIVE = 2,
}

export enum MilestoneTypeEnum {
  NOT_REACH_TARGET = 0,
  REACH_TARGET = 1,
  REDEEM = 2,
}

export type PriceInfo = {
  singleDraw: {
    price: number;
    discountPrice: number;
    prop: number;
  };
  collectiveDraw: {
    price: number;
    discountPrice: number;
  };
};
export type Enemy = {
  imageSmall: string;
  imageLarge: string;
  imageName: string;
  maxPoint: number;
  minPoint: number;
  desc: string;
};
export type Balance = {
  coupon: number;
  prop: number;
};
export type RewardItem = {
  name: string;
  image: string;
  isGrand: boolean;
};
export type EncounteredEnemy = {
  image: string;
  point: number;
  isWin: boolean;
};
export type HistoryItem = {
  id: number;
  name: string;
  image: string;
};
export type MilestoneItem = {
  id: number;
  name: string;
  image: string;
  pointThreshold: number;
  redeemed: boolean;
}

export type CurrentDraw = {
  drawType: DrawTypeEnum;
  coupon?: number;
  prop?: number;
};

export interface ErrorMessageResponse {
  error: string;
  code?: number;
  mailCount?: number;
}
export interface ProfileResponse {
  tutorialNum: number;
  youtubeVideoId: string;
  uniqueId: string;
  nickname: string;
  balance: Balance;
  fanPoint: number;
  collectiveDrawCount: number;
  maxSelectedItemCount: number;
  priceInfo: PriceInfo;
  enemies: Enemy[];
  history: HistoryItem[];
  milestones: MilestoneItem[];
  grandPrizePoint: number;
  isConfirmed: boolean;
}
export interface DrawResponse {
  hitJackpot: boolean;
  fanPoint: number;
  balance: Balance;
  encounteredEnemies: EncounteredEnemy[];
  rewards: RewardItem[];
  history: HistoryItem[];
  milestones: MilestoneItem[];
  isFirstDraw: boolean;
}
export interface HistoryClaimResponse {
  history: HistoryItem[];
  showHint: boolean;
}
export interface MilestoneRedeemResponse {
  milestones: MilestoneItem[];
}
export interface AgreeResponse {
  isAgree: boolean;
}

export enum ErrorTypesEnum {
  // User
  ERROR__OUT_GAME = 900,
  ERROR__INSUFFICIENT_BALANCE = 901,
  // Browser
  ERROR__COPY_FAIL = 910,
  // Network
  ERROR__FRONT_END_ERROR = 920,
  ERROR__SERVER_ERROR = 921,
}
