import { LocalStatusEnum, LocalStatus } from '@/types';

let uid: string;
const { VERSION: version, LAUNCH_DATE: launchDate } = process.env;
const currentVersion = `${version}_${launchDate}`;
const getStorageVersion = () => localStorage.getItem('version_name');

function resetStorage() {
  if (!getStorageVersion()) return;
  // clear localStorage if not the same version
  if (getStorageVersion() !== currentVersion)
    localStorage.removeItem(`storage_${uid}`);
}
function initStorage(userUid: string) {
  resetStorage(); // reset
  uid = userUid; // set uid
}
function setStorage(key: LocalStatusEnum, val = true) {
  if (!uid) return;
  if (!getStorageVersion()) {
    localStorage.setItem('version_name', currentVersion);
  }
  const storage = JSON.parse(localStorage.getItem(`storage_${uid}`) as string) || {};

  localStorage.setItem(
    `storage_${uid}`,
    JSON.stringify({
      ...storage,
      [key]: val,
    }),
  );
}
function getStorage(key: LocalStatusEnum): LocalStatus[LocalStatusEnum] | null {
  let storage: LocalStatus = {};
  if (localStorage.getItem(`storage_${uid}`)) {
    storage = JSON.parse(localStorage.getItem(`storage_${uid}`) as string);
  }
  return storage[key] ? storage[key] : null;
}

export { initStorage, setStorage, getStorage };
