import { createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementBlock as _createElementBlock, withCtx as _withCtx } from "vue"

const _hoisted_1 = {
  key: 0,
  class: "main"
}
const _hoisted_2 = ["src"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Home = _resolveComponent("Home")!
  const _component_ThaiAgreement = _resolveComponent("ThaiAgreement")!
  const _component_FullScreenAnimation = _resolveComponent("FullScreenAnimation")!
  const _component_Modals = _resolveComponent("Modals")!
  const _component_Loading = _resolveComponent("Loading")!
  const _component_ClickEffect = _resolveComponent("ClickEffect")!

  return (_openBlock(), _createBlock(_component_ClickEffect, null, {
    default: _withCtx(() => [
      (_ctx.isInitialized)
        ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
            _createElementVNode("video", {
              src: _ctx.videoPath,
              class: "video-bg",
              autoplay: "",
              loop: "",
              muted: "",
              playsinline: "",
              preload: "metadata"
            }, null, 8, _hoisted_2),
            _createVNode(_component_Home),
            (_ctx.isTHRegion)
              ? (_openBlock(), _createBlock(_component_ThaiAgreement, {
                  key: 0,
                  show: _ctx.isShowThaiAgreement
                }, null, 8, ["show"]))
              : _createCommentVNode("", true),
            _createVNode(_component_FullScreenAnimation)
          ]))
        : _createCommentVNode("", true),
      _createVNode(_component_Modals),
      (_ctx.loadedImgPercentage !== 100)
        ? (_openBlock(), _createBlock(_component_Loading, {
            key: 1,
            progress: _ctx.loadedImgPercentage
          }, null, 8, ["progress"]))
        : _createCommentVNode("", true),
      (_ctx.loadedImgPercentage === 100 && _ctx.isLoading)
        ? (_openBlock(), _createBlock(_component_Loading, { key: 2 }))
        : _createCommentVNode("", true)
    ]),
    _: 1
  }))
}