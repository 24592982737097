
import { computed } from 'vue';
import { useDraw, useModal } from '@/store';
import { DrawTypeEnum } from '@/types';
import Modal from '../Base/Modal.vue';

export default {
  components: {
    Modal,
  },
  setup() {
    const { setModal } = useModal();
    const { drawState, postDraw } = useDraw();
    const currentDraw = computed(() => drawState.currentDraw);
    const collectiveDrawCount = computed(() => drawState.collectiveDrawCount);

    function handleConfirm() {
      setModal(null);
      postDraw();
    }

    return {
      currentDraw,
      collectiveDrawCount,
      setModal,
      handleConfirm,
      DrawTypeEnum,
    };
  },
};
