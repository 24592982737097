
import { computed } from 'vue';
import { useProfile, useModal } from '@/store';
import { ModalTypeEnum } from '@/types';
import Modal from '../Base/Modal.vue';

export default {
  components: {
    Modal,
  },
  setup() {
    const { profileState } = useProfile();
    const { setModal } = useModal();
    const selectEnemy = computed(() => profileState.selectEnemy);
    const imageLarge = computed(() => profileState.selectEnemy.imageLarge);

    function handleCloseModal() {
      setModal(null);
    }

    return {
      selectEnemy,
      handleCloseModal,
      ModalTypeEnum,
      imageLarge,
    };
  },
};
