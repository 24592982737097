
import { reactive, computed, watch } from 'vue';
import { ModalEnum, LocalStatusEnum, ModalTypeEnum } from '@/types';
import { getStorage } from '@/storageUtils';
import { useHistory, useModal } from '@/store';
import Modal from '../Base/Modal.vue';

export default {
  components: {
    Modal,
  },
  setup() {
    const { historyState, setCurrentClaim, postHistoryClaim } = useHistory();
    const { setModal } = useModal();
    const selectedRecord: Record<string, boolean> = reactive({});
    const history = computed(() => historyState.history);
    const selectedCount = computed(() =>
      Object.keys(selectedRecord).reduce((pre, cur) => {
        if (selectedRecord[cur]) {
          return pre + 1;
        }
        return pre;
      }, 0),
    );
    const isClaimable = computed(() => selectedCount.value > 0);
    const maxSelectedItemCount = computed(() =>
      Math.min(history.value.length, historyState.maxSelectedItemCount),
    );
    const isSelectable = computed(
      () => selectedCount.value < maxSelectedItemCount.value,
    );

    function handleSelect(i: number) {
      if (selectedRecord[i]) {
        selectedRecord[i] = false;
      } else {
        if (!isSelectable.value) return;
        selectedRecord[i] = true;
      }
    }
    function handleQuickSelect() {
      if (!isSelectable.value) {
        Object.keys(selectedRecord).forEach((key) => {
          selectedRecord[key] = false;
        });
        return;
      }

      let i = 0;
      while (isSelectable.value) {
        const historyId = historyState?.history[i]?.id;
        if (!selectedRecord[historyId]) {
          selectedRecord[historyId] = true;
        }
        i++;
      }
    }
    function handleClickSend() {
      setCurrentClaim(
        historyState.history.filter((item) => selectedRecord[item.id]),
      );
      getStorage(LocalStatusEnum.IS_SKIP_SEND_CONFIRM)
        ? postHistoryClaim()
        : setModal(ModalEnum.CONFIRM_CLAIM);
    }

    watch([history], () => {
      for (const key in selectedRecord) {
        delete selectedRecord[key];
      }
    });

    return {
      history,
      isClaimable,
      selectedRecord,
      isSelectable,
      setModal,
      handleSelect,
      handleQuickSelect,
      postHistoryClaim,
      handleClickSend,
      ModalTypeEnum,
    };
  },
};
