
import { toRef } from 'vue';
import { useProfile } from '@/store';

export default {
  setup() {
    const { profileState } = useProfile();
    const balance = toRef(profileState, 'balance');

    return {
      balance,
    };
  },
};
