
import { computed } from 'vue';
import { useModal, useGeneral } from '@/store';
import Modal from '../Base/Modal.vue';

const notClosableErrorMessages = [
  'ERROR__SERVER_ERROR',
  'ERROR__NOT_EVENT_PERIOD',
  'ERROR__UNDER_MAINTENANCE',
  'ERROR__NOT_INGAME',
  // 20240327 ADDED
  'ERROR__GOP_LOGIN_FAILED',
  'ERROR__GAME_LOGIN_FAILED',
  'ERROR__EVENT_LOGIN_FAILED',
  'ERROR__FRONT_END_ERROR',
  'ERROR__NOT_ONLINE',
];
export default {
  components: {
    Modal,
  },
  setup() {
    const { setModal } = useModal();
    const { generalState } = useGeneral();

    const errorMessage = computed(() => generalState.errorMessage);
    const errorCode = computed(() => generalState.errorCode);
    const mailCount = computed(() => generalState.mailCount);
    const isCloseable = computed(
      () => notClosableErrorMessages.indexOf(generalState.errorMessage) === -1,
    );

    return {
      errorMessage,
      errorCode,
      mailCount,
      setModal,
      isCloseable,
    };
  },
};
