
import ButtonHistory from '../components/ButtonHistory.vue';
import InfoBlock from '../components/InfoBlock/index.vue';
import GameBoard from '../components/GameBoard/index.vue';
import Milestone from '../components/Milestone/index.vue';

export default {
  name: 'Home',
  components: {
    InfoBlock,
    ButtonHistory,
    GameBoard,
    Milestone,
  },
};
